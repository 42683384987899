import { Event } from "../utils/types";
import { actionTypes } from "./action.types";

export interface EventState {
  loading: boolean;
  error: boolean;
  eventsByChamberId: Event[];
  eventById: Event | null;
}

const initialState: EventState = {
  loading: false,
  error: false,
  eventsByChamberId: [],
  eventById: null,
};

const EventReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GETEVENTBYID_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETEVENTBYID_SUCCESS:
      return { ...state, loading: false, eventById: payload as Event };
    case actionTypes.GETEVENTBYID_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.GETEVENTBYCHAMBERID_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETEVENTBYCHAMBERID_SUCCESS:
      return {
        ...state,
        loading: false,
        eventsByChamberId: payload as Event[],
      };
    case actionTypes.GETEVENTBYCHAMBERID_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.ADDEVENT_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.ADDEVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        equipsByHallId: [payload as Event, ...state?.eventsByChamberId],
      };
    case actionTypes.ADDEQUIP_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.UPDATEEVENT_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.UPDATEEVENT_SUCCESS:
      return { ...state, loading: false, equipsById: payload as Event };
    case actionTypes.UPDATEEVENT_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.DELETEEVENT_SUCCESS: {
      let updatedEventsByHallId: Event[] = state.eventsByChamberId.filter(
        (item) => {
          return item.event_id !== payload.event_id;
        }
      );
      return { ...state, equipsByHallId: updatedEventsByHallId as Event[] };
    }
    case actionTypes.DELETEEVENT_ERROR:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};

export default EventReducer;
