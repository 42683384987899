/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// Authentication layout components
import BasicLayout from "../../../layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import { useSnackbar } from "notistack";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface Credentials {
  email: string;
  password: string;
}

function Basic() {
  const [cred, setCred] = useState<Credentials>({ email: "", password: "" });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const handleClickShowPassword = (): void => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  // console.log(cred);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCred({
      ...cred,
      [name]: value,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const password = params.get('password');

    if (email && password) {
      // setLoading(true);
      const login = async () => {
        try {
          const apiUrl = "https://api.pixacci.com/chamber/login";
          const updatedCred = { email,password, role: "chamber" };
          const response: AxiosResponse<{
            success: boolean;
            message: { user_id: string; accessToken: string };
          }> = await axios.post(apiUrl, updatedCred);
          if (response.data.success) {
            localStorage.setItem("user_id", response.data.message.user_id);
            localStorage.setItem("token", response.data.message.accessToken);
            localStorage.setItem("isLoggedIn", "true");
            localStorage.setItem("role", "admin");
            enqueueSnackbar("Logged in successfully.", { variant: "success" });
            navigate("/dashboard");
            
            window.location.reload();
          } else {
            enqueueSnackbar("Login failed, something went wrong.", {
              variant: "error",
            });
            console.log("Login failed, something went wrong.");
          }
        } catch (error: AxiosError | any) {
          enqueueSnackbar(
            "Login failed, Check your credentials or User not found.",
            { variant: "error" }
          );
          console.log(error);
        }
      };

      login();
    }
  }, []);

  const handleLogin = async () => {
    try {
      const apiUrl = "https://api.pixacci.com/chamber/login";
      const updatedCred = { ...cred, role: "chamber" };
      const response: AxiosResponse<{
        success: boolean;
        message: { user_id: string; accessToken: string };
      }> = await axios.post(apiUrl, updatedCred);
      // console.log(response);
      if (response.data.success) {
        localStorage.setItem("user_id", response.data.message.user_id);
        localStorage.setItem("token", response.data.message.accessToken);
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("role", "admin");
        enqueueSnackbar("Logged in successfully.", { variant: "success" });
        navigate("/dashboard");
        window.location.reload();
      } else {
        enqueueSnackbar("Login failed, something went wrong.", {
          variant: "error",
        });
        console.log("Login failed, something went wrong.");
      }
    } catch (error: AxiosError | any) {
      enqueueSnackbar(
        "Login failed, Check your credentials or User not found.",
        { variant: "error" }
      );
      console.log(error);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          {...({} as any)}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={1}
          mt={-3}
          p={1}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            {...({} as any)}
            variant="h4"
            fontWeight="medium"
            color="white"
            mt={1}
          >
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox {...({} as any)} pt={4} pb={3} px={3}>
          <MDBox {...({} as any)} component="form" role="form">
            <MDBox {...({} as any)} mb={2}>
              <MDInput
                {...({} as any)}
                onChange={handleChange}
                name="email"
                type="email"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox
              {...({} as any)}
              mb={2}
              style={{ display: "flex", placeItems: "center" }}
            >
              <MDInput
                {...({} as any)}
                onChange={handleChange}
                name="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox {...({} as any)} mt={4} mb={1}>
              <MDButton
                {...({} as any)}
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleLogin}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
