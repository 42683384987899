import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import MDBackdrop from "../MDBackdrop";
import * as XLSX from 'xlsx';
import axios from "axios";

function POPUploadUserCSV(props) {
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [isBackdrop, setIsBackdrop] = useState(false);
    const { onClose } = props;
    const token = localStorage.getItem("token");

    const handleFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        const f = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            const readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            const parsedData = XLSX.utils.sheet_to_json(ws, {
                header: 1,
                blankrows: false
            });
            console.log(parsedData);
            setData(parsedData);
        };
        reader.readAsBinaryString(f);
    };

    const fetchImage = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new File([blob], url.split('/').pop(), { type: blob.type });
        } catch (error) {
            console.error("Error fetching image: ", error);
            return null;
        }
    };

    const fetchData = async (file, fileName, data) => {
        console.log(fileName);
        console.log(file);
        console.log(data);
        let status1 = 1;
        for (let i = 1; i < data.length; i++) {
            const bodyFormData = new FormData();
            for (let j = 0; j < data[i].length; j++) {
                if (['logo', 'certificate', 'pan', 'cin'].includes(data[0][j])) {
                    // Fetch image from URL and append to FormData
                    const imageUrl = data[i][j];
                    const imageFile = await fetchImage(imageUrl);
                    if (imageFile) {
                        bodyFormData.append(data[0][j], imageFile);
                    } else {
                        status1 = 0;
                        alert(`Error fetching image for row ${i + 1}.`);
                        window.location.reload();
                        break;
                    }
                } else {
                    bodyFormData.append(data[0][j], data[i][j]);
                }
            }
            if (status1 === 0) {
                break;
            }

            setIsBackdrop(true);
            const apiUrl = "https://api.pixacci.com/";
            await axios({
                method: "post",
                url: apiUrl + "user/",
                data: bodyFormData,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then((response) => {
                    console.log(response.data.message);
                    if (response.data.status === false) {
                        status1 = 0;
                        alert(`There is some problem while loading row '${i + 1}' with username '${data[i][2]}'. Please rectify the problem and try again.`);
                        window.location.reload();
                    }
                })
                .catch((error) => {
                    status1 = 0;
                    console.error(error);
                    alert(`There is some problem while loading row '${i + 1}' with username '${data[i][2]}'. Please rectify the problem and try again.`);
                    window.location.reload();
                });

            if (status1 === 0) {
                break;
            }
        }

        if (status1 === 1) {
            alert('File is uploaded successfully!');
            window.location.reload();
        }

        setIsBackdrop(false);
    };

    const handleSubmit = () => {
        if (!file) {
            return;
        }
        setIsBackdrop(true);
        fetchData(file, fileName, data);
    };

    const handleClose = () => {
        onClose(false);
    };

    return (
        <>
            <MDBackdrop isBackdrop={isBackdrop} />
            <Dialog open={props.isDialog} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Select CSV File</DialogTitle>
                <DialogContent>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox pt={2}>
                            <FormControl sx={{ width: "100%" }}>
                                <MDInput type="file" name="file" onChange={handleFile} fullWidth required />
                            </FormControl>
                        </MDBox>
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmit} autoFocus>
                        Upload File
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default POPUploadUserCSV;
