import { Hall } from "../utils/types";
import { actionTypes } from "./action.types";

export interface HallManagementState {
  loading: boolean;
  error: boolean;
  halls: Hall[];
  hallById: Hall | null;
}

const initialState: HallManagementState = {
  loading: false,
  error: false,
  halls: [],
  hallById: null,
};

const HallManagementReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GETHALL_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETHALL_SUCCESS:
      return { ...state, loading: false, halls: payload as Hall[] };
    case actionTypes.GETHALL_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.GETHALLBYID_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETHALLBYID_SUCCESS:
      return { ...state, loading: false, hallById: payload as Hall };
    case actionTypes.GETHALLBYID_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.ADDHALL_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.ADDHALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        halls: [payload as Hall, ...state?.halls],
      };
    case actionTypes.ADDHALL_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.UPDATEHALL_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.UPDATEHALL_SUCCESS:
      return { ...state, loading: false, hallById: payload as Hall };
    case actionTypes.UPDATEHALL_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.DELETEHALL_SUCCESS: {
      let updatedHallsByHallId: Hall[] = state?.halls.filter((item) => {
        return item.id !== payload.id;
      });
      return { ...state, halls: updatedHallsByHallId as Hall[] };
    }
    case actionTypes.DELETEHALL_ERROR:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};

export default HallManagementReducer;
