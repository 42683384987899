// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

// @mui material components
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { AddUser, GetUserById, UpdateUser } from "../../../Redux/action";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addUserValidations } from "../../../utils/user_Validation";
import useFormValidator from "../../../utils/form_validation";
import { useSnackbar } from "notistack";
import { saveAs } from "file-saver";
import axios from "axios";
import { State, City } from "country-state-city";
import {
  useAppDispatch,
  RootState,
  useAppSelector,
} from "../../../Redux/store";
import { User } from "../../../utils/types";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";

const JoinMembership: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const idFromLocation = location?.state?.id;
  const [fileInputs, setFileInputs] = useState({
    certificate: null,
    pan: null,
    cin: null,
    logo: null,
  });
  const dispatch = useAppDispatch();
  const [dateError, setDateError] = useState("");
  const [showDoc, setShowDoc] = useState(true);
  const { userById } = useAppSelector(
    (store: RootState) => store.MembershipReducer
  );
  const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const chamber_id = localStorage.getItem("user_id");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [states, setStates] = useState<{ name: string; isoCode: string }[]>([]);
  const [cities, setCities] = useState<{ name: string }[]>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const menuItemsOfOrgCategory = [
    "Agriculture",
    "Agri-Individual",
    "Educational / Social Institution",
    "Executive Committee",
    "Exporter",
    "Financial Institution",
    "Industry / Trade Association / Govt / Semi Govt Organization",
    "Manufacturer",
    "Professional",
    "Services",
    "Societies / Trust",
    "Trader",
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  };
  const payloadObj: User = {
    chamber_id: +chamber_id! as number,
    first_name: "",
    last_name: "",
    phone_number: null,
    email: "",
    contact_person: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    gst_number: "",
    status: "",
    organization_name: "",
    organization_category: "",
    subscription_from: "",
    subscription_till: "",
  };

  const [payload, setPayload] = useState<User>(
    idFromLocation ? (userById as User) : { ...payloadObj }
  );
  const [password, setPassword] = useState("");

  useEffect(() => {
    const statesData = State.getStatesOfCountry("IN");
    setStates(statesData);
  }, []);

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    // console.log("event", event.target)
    const selectedStateCode = event.target.value;
    setSelectedState(selectedStateCode);
    const citiesData = City.getCitiesOfState("IN", selectedStateCode);
    setCities(citiesData);
    setSelectedCity("");

    setPayload({ ...payload, state: selectedStateCode });
  };

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    const selectedCityValue = event.target.value;
    setSelectedCity(selectedCityValue);
    setPayload({ ...payload, city: selectedCityValue });
  };
  // console.log("cities", cities);
  const handleInitialCities = (stateCode: string): void => {
    const citiesData = City.getCitiesOfState("IN", stateCode);
    setCities(citiesData);
  };

  const handleOrganizationCategory = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const { errors, validateForm } = useFormValidator(
    payload,
    addUserValidations
  );

  const [passwordChangeData, setPasswordChangeData] = useState({
    current_password: "",
    new_password: "",
    confirmNewPassword: "",
  });

  const handlePasswordChange = async () => {
    const token = localStorage.getItem("token");
    // console.log(token);
    try {
      const { current_password, new_password, confirmNewPassword } =
        passwordChangeData;

      if (!current_password || !new_password || !confirmNewPassword) {
        enqueueSnackbar("All fields are reuired", { variant: "error" });
        return;
      }

      if (new_password !== confirmNewPassword) {
        enqueueSnackbar("Confirm password doesn't match New password", {
          variant: "error",
        });
        return;
      }

      const passwordPayload = {
        email: payload.email,
        current_password: current_password,
        new_password: new_password,
      };

      const res = await axios.patch(
        `https://api.pixacci.com/user/updatepassword`,
        passwordPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.success) {
        enqueueSnackbar("Password updated successfully.", {
          variant: "success",
        });
        navigate("/membership");
      } else {
        enqueueSnackbar("Failed to update password. Please try again.", {
          variant: "error",
        });
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Old password is incorrect.", { variant: "error" });
    }
  };

  const handlePasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setPasswordChangeData({
      ...passwordChangeData,
      [name]: value,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const files = e.target.files?.[0];
    if (
      name === "logo" ||
      name === "certificate" ||
      name === "pan" ||
      name === "cin"
    ) {
      setFileInputs({ ...fileInputs, [name]: files });
    } else if (name === "password") {
      setPassword(value);
    } else {
      setPayload({ ...payload, [name]: value });
    }

    // Validation for choosing the correct date
    if (
      name === "subscription_from" &&
      payload?.subscription_till &&
      value > payload?.subscription_till
    ) {
      setDateError("Starting date cannot be later than ending date");
    } else if (
      name === "subscription_till" &&
      payload?.subscription_from &&
      value < payload?.subscription_from
    ) {
      setDateError("Ending date cannot be earlier than starting date");
    } else if (
      name === "subscription_from" &&
      payload?.subscription_till &&
      value === payload?.subscription_till
    ) {
      setDateError("Starting date and ending date cannot be the same");
    } else if (
      name === "subscription_till" &&
      payload?.subscription_from &&
      value === payload?.subscription_from
    ) {
      setDateError("Ending date and starting date cannot be the same");
    } else {
      setDateError("");
    }
  };

  const handleResponseAddUser = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${"Member added successfully."}`, {
        variant: "success",
      });
      navigate("/membership");
    } else {
      if (res?.data?.message) {
        enqueueSnackbar(`${res?.data?.message}`, { variant: "error" });
      } else if (res?.data?.error) {
        enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
      } else {
        enqueueSnackbar(`Something went wrong, Please check all fields.`, {
          variant: "error",
        });
      }
    }
  };

  const handleResponseUpdateUser = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${res.data.message}`, { variant: "success" });
      navigate("/membership");
    } else {
      enqueueSnackbar(`${res.data.error}`, { variant: "error" });
    }
  };

  const handleAddUser = async () => {
    const validateFormError = validateForm();
    console.log(validateFormError)

    if (!validateFormError) {
      const formData = new FormData();
      if (type === "add") {
        formData.append("password", password);
      }
      Object.entries(payload).forEach(([key, value]) => {
        if (key !== "password" || (key === "password" && type === "add")) {
          formData.append(key, value);
        }
      });

      Object.entries(fileInputs).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      if (type === "add") {
        dispatch<any>(AddUser(formData, handleResponseAddUser));
      } else {
        dispatch<any>(
          UpdateUser(idFromLocation, formData, handleResponseUpdateUser)
        );
      }
    } else {
      enqueueSnackbar(
        "Form validation failed. Please fill in all required fields.",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (idFromLocation) {
      dispatch<any>(GetUserById(idFromLocation, handleInitialCities));
    }
  }, [idFromLocation, dispatch]);

  useEffect(() => {
    if (userById) {
      setPayload(userById);
    }
  }, [userById]);

  useEffect(() => {
    if (type === "add") {
      setPayload(payloadObj);
      setShowDoc(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("payload", payload);
  // console.log("userById", userById);
  // console.log("idFromLocation", idFromLocation);
  // console.log("type", type);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        {...({} as any)}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={3}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography
          {...({} as any)}
          variant="h5"
          fontWeight="medium"
          color="white"
          mt={1}
        >
          Membership
        </MDTypography>
      </MDBox>
      <MDBox
        {...({} as any)}
        mx={2}
        mt={3}
        py={3}
        p={4}
        mb={2}
        variant="gradient"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography {...({} as any)} variant="h5" pb={2}>
          Basic Details
        </MDTypography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          gap={3}
          pb={2}
        >
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="First Name"
            name="first_name"
            value={payload?.first_name || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Last Name"
            name="last_name"
            value={payload?.last_name || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="number"
            label="Phone Number"
            name="phone_number"
            value={payload?.phone_number}
            margin="dense"
            sx={widthStyle}
            InputLabelProps={{
              shrink: !!payload?.phone_number,
            }}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Contact Person"
            name="contact_person"
            value={payload?.contact_person || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="email"
            label="Email"
            name="email"
            value={payload?.email || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="password"
            label="Password"
            name="password"
            value={password || payload?.password}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
            onClick={(): void => {
              if (showDoc) handleOpen();
            }}
            inputProps={{
              readOnly: showDoc,
              endAdornment: showDoc ? (
                <InputAdornment position="end">
                  <EditIcon sx={{ cursor: "pointer" }} />
                </InputAdornment>
              ) : (
                ""
              ),
            }}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDBox {...({} as any)} sx={style}>
              <MDTypography
                {...({} as any)}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Update Password
              </MDTypography>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                gap={3}
                pb={2}
                pt={2}
              >
                <MDInput
                  {...({} as any)}
                  type="text"
                  label="Current Password"
                  name="current_password"
                  value={passwordChangeData?.current_password}
                  margin="dense"
                  fullWidth
                  onChange={handlePasswordInputChange}
                />
                <MDInput
                  {...({} as any)}
                  type="text"
                  label="New Password"
                  name="new_password"
                  value={passwordChangeData?.new_password}
                  margin="dense"
                  fullWidth
                  onChange={handlePasswordInputChange}
                />
                <MDInput
                  {...({} as any)}
                  type="text"
                  label="Confirm Password"
                  name="confirmNewPassword"
                  value={passwordChangeData?.confirmNewPassword}
                  margin="dense"
                  fullWidth
                  onChange={handlePasswordInputChange}
                />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                pt={2}
                pb={0.5}
              >
                <MDButton
                  {...({} as any)}
                  variant="gradient"
                  color="info"
                  onClick={handlePasswordChange}
                >
                  Update
                </MDButton>
              </Grid>
            </MDBox>
          </Modal>
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Address"
            name="address"
            value={payload?.address || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <FormControl required sx={widthStyle}>
            <InputLabel>State</InputLabel>
            <Select
            label="State"
              style={{ height: "45px" }}
              value={payload?.state || selectedState}
              onChange={handleStateChange}
            >
              {states.map((state) => (
                <MenuItem
                  key={state.isoCode}
                  value={state.isoCode}
                  // name={state.name}
                >
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl required sx={widthStyle}>
            <InputLabel>City</InputLabel>
            <Select
              label="City"
              style={{ height: "45px" }}
              value={payload?.city || selectedCity}
              onChange={handleCityChange}
            >
              {cities.map((city) => (
                <MenuItem key={city.name} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MDInput
            {...({} as any)}
            required
            type="number"
            label="Pincode"
            name="pincode"
            value={payload?.pincode || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <FormControl sx={{ width: "45%" }} component="fieldset" required>
            <FormLabel>Status</FormLabel>
            <RadioGroup
              row
              aria-label="status"
              name="status"
              value={payload?.status || ""}
              onChange={handleChange}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                value="online"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="offline"
                control={<Radio />}
                label="Inactive"
              />
              {showDoc && (
                <MDButton
                  {...({} as any)}
                  variant="contained"
                  disabled
                  color="error"
                >
                  {payload?.Payment || "Unpaid"}
                </MDButton>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <MDTypography {...({} as any)} variant="h6" pt="2" fontWeightLighter>
          Organization Details
        </MDTypography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          gap={3}
          pt={2}
        >
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Organization Name"
            name="organization_name"
            value={payload?.organization_name || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <FormControl required sx={widthStyle}>
            <InputLabel>Organization Category</InputLabel>
            <Select
              style={{ height: "45px" }}
              label="Organization Category"
              name="organization_category"
              value={payload?.organization_category || ""}
              onChange={handleOrganizationCategory}
            >
              {menuItemsOfOrgCategory?.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="GST Number"
            name="gst_number"
            value={payload?.gst_number || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
        </Grid>
        <MDTypography {...({} as any)} variant="h6" pt={2} fontWeightLighter>
          Membership Period
        </MDTypography>
        <Grid container direction="row" justifyContent="space-between">
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            gap={3}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Membership Date (From)
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="date"
              // label=""
              name="subscription_from"
              value={payload?.subscription_from?.slice(0, 10) || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            gap={3}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Membership Date (To)
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="date"
              // label=""
              name="subscription_till"
              value={payload?.subscription_till?.slice(0, 10) || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" pt={""}>
          <Grid
            container
            justifyContent="space-between"
            pt={4}
            flexDirection="row"
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid item xs={4}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                pt={2}
                fontWeightLighter
              >
                {showDoc ? "Update Logo" : "Add Logo"}
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  {" "}
                  (Preferred dimensions are 1350 x 376)
                </span>
              </MDTypography>
              <MDInput
                {...({} as any)}
                type="file"
                name="logo"
                margin="dense"
                sx={{ width: "100%" }}
                onChange={handleChange}
              />
              {showDoc && (
                <>
                  <MDButton
                    {...({} as any)}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(`${docUrl}${payload?.logo}`, "_blank");
                    }}
                  >
                    View Document
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    component="a"
                    variant="body2"
                    onClick={() => {
                      const fileUrl = `${docUrl}${payload?.logo}`;
                      saveAs(fileUrl, `${payload?.logo}`);
                    }}
                  >
                    Download Document
                  </MDButton>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                pt={2}
                fontWeightLighter
              >
                {showDoc ? "Update GST Certificate" : "Add GST Certificate"}
              </MDTypography>
              <MDInput
                {...({} as any)}
                type="file"
                name="certificate"
                margin="dense"
                sx={{ width: "100%" }}
                onChange={handleChange}
              />
              {showDoc && (
                <>
                  <MDButton
                    {...({} as any)}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(
                        `${docUrl}${payload?.gst_certificate}`,
                        "_blank"
                      );
                    }}
                  >
                    View Document
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    component="a"
                    variant="body2"
                    onClick={() => {
                      const fileUrl = `${docUrl}${payload?.gst_certificate}`;
                      saveAs(fileUrl, `${payload?.gst_certificate}`);
                    }}
                  >
                    Download Document
                  </MDButton>
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            flexDirection="row"
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid item xs={4}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                pt={2}
                fontWeightLighter
              >
                {showDoc ? "Update PAN" : "Add PAN"}
              </MDTypography>
              <MDInput
                {...({} as any)}
                type="file"
                name="pan"
                margin="dense"
                fullWidth
                onChange={handleChange}
              />
              {showDoc && (
                <>
                  <MDButton
                    {...({} as any)}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(`${docUrl}${payload?.pan}`, "_blank");
                    }}
                  >
                    View Document
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    component="a"
                    variant="body2"
                    onClick={() => {
                      const fileUrl = `${docUrl}${payload?.pan}`;
                      saveAs(fileUrl, `${payload?.pan}`);
                    }}
                  >
                    Download Document
                  </MDButton>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                pt={2}
                fontWeightLighter
              >
                {showDoc ? "Update CIN" : "Add CIN"}
              </MDTypography>
              <MDInput
                {...({} as any)}
                type="file"
                name="cin"
                margin="dense"
                sx={{ width: "100%" }}
                onChange={handleChange}
              />
              {showDoc && (
                <>
                  <MDButton
                    {...({} as any)}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(`${docUrl}${payload?.cin}`, "_blank");
                    }}
                  >
                    View Document
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    component="a"
                    variant="body2"
                    onClick={() => {
                      const fileUrl = `${docUrl}${payload?.cin}`;
                      saveAs(fileUrl, `${payload?.cin}`);
                    }}
                  >
                    Download Document
                  </MDButton>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {(errors?.first_name && (
          <span style={{ fontSize: "12px", color: "red" }}>
            {errors?.first_name}
          </span>
        )) ||
          (errors?.last_name && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.last_name}
            </span>
          )) ||
          (errors?.phone_number && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.phone_number}
            </span>
          )) ||
          (errors?.email && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.email}
            </span>
          )) ||
          (errors?.contact_person && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.contact_person}
            </span>
          )) ||
          // (errors?.password && (
          //   <span style={{ fontSize: "12px", color: "red" }}>
          //     {errors?.password}
          //   </span>
          // )) ||
          (errors?.address && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.address}
            </span>
          )) ||
          (errors?.state && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.state}
            </span>
          )) ||
          (errors?.city && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.city}
            </span>
          )) ||
          (errors?.pincode && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.pincode}
            </span>
          )) ||
          (errors?.gst_number && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.gst_number}
            </span>
          )) ||
          (errors?.organization_name && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.organization_name}
            </span>
          )) ||
          (errors?.organization_category && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.organization_category}
            </span>
          )) ||
          (errors?.subscription_from && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.subscription_from}
            </span>
          )) ||
          (errors?.subscription_till && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.subscription_till}
            </span>
          ))}

        {dateError && (
          <span style={{ fontSize: "12px", color: "red" }}>{dateError}</span>
        )}
      </MDBox>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
        <MDButton
          {...({} as any)}
          variant="gradient"
          color="info"
          onClick={handleAddUser}
        >
          {type === "add" ? "Add Member" : "Update"}
        </MDButton>
      </Grid>
    </DashboardLayout>
  );
};

export default JoinMembership;
