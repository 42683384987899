import { actionTypes } from "./action.types";
import { User , Chamber } from "../utils/types";

export interface UserState {
  loading: boolean;
  error: boolean;
  user: User[];
  userById: User | null;
  chamberById: Chamber | null;
  service_id: string | string[] | number[];
}

const initialState: UserState = {
  loading: false,
  error: false,
  user: [],
  userById: null,
  chamberById: null,
  service_id: [],
};

const MembershipReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.ADDUSER_LOADING:
    case actionTypes.GETUSER_LOADING:
    case actionTypes.GETUSERBYID_LOADING:
    case actionTypes.GETCHAMBERBYID_LOADING:
      return { ...state, loading: true, error: false };

    case actionTypes.ADDUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: [payload as User, ...state?.user],
      };

    case actionTypes.ADDUSER_ERROR:
    case actionTypes.GETUSER_ERROR:
    case actionTypes.GETUSERBYID_ERROR:
    case actionTypes.GETCHAMBERBYID_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.GETUSER_SUCCESS:
      return { ...state, loading: false, user: payload as User[] };

    case actionTypes.GETUSERBYID_SUCCESS:
      return { ...state, loading: false, userById: payload as User };

    case actionTypes.GETCHAMBERBYID_SUCCESS:
      return { ...state, loading: false, chamberById: payload as Chamber };

    case actionTypes.GETservice_id_SUCCESS:
      return {
        ...state,
        loading: false,
        service_id: payload as string | string[] | number[],
      };

    default:
      return state;
  }
};

export default MembershipReducer;
