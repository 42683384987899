import { Equipment } from "../utils/types";
import { actionTypes } from "./action.types";

export interface EquipmentState {
  loading: boolean;
  error: boolean;
  equipsByHallId: Equipment[];
  equipsById: Equipment | null;
}

const initialState: EquipmentState = {
  loading: false,
  error: false,
  equipsByHallId: [],
  equipsById: null,
};

const EquipmentReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GETEQUIPSBYID_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETEQUIPSBYID_SUCCESS:
      return { ...state, loading: false, equipsById: payload as Equipment };
    case actionTypes.GETEQUIPSBYID_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.GETEQUIPSBYHALLID_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETEQUIPSBYHALLID_SUCCESS:
      return {
        ...state,
        loading: false,
        equipsByHallId: payload as Equipment[],
      };
    case actionTypes.GETEQUIPSBYHALLID_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.ADDEQUIP_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.ADDEQUIP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        equipsByHallId: [payload as Equipment, ...state?.equipsByHallId],
      };
    case actionTypes.ADDEQUIP_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.UPDATEEQUIP_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.UPDATEEQUIP_SUCCESS:
      return { ...state, loading: false, equipsById: payload as Equipment };
    case actionTypes.UPDATEEQUIP_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.DELETEEQUIP_SUCCESS: {
      let updatedEquipsByHallId: Equipment[] = state.equipsByHallId.filter(
        (item) => {
          return item.equipment_id !== payload.equipment_id;
        }
      );
      return { ...state, equipsByHallId: updatedEquipsByHallId as Equipment[] };
    }
    default:
      return state;
  }
};

export default EquipmentReducer;
