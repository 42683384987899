// useFormValidator.js
import { useState } from "react";
import * as Yup from "yup";

interface Formdata {
  [key: string]: any;
}

interface ValidationSchema {
  validateSync(data: Formdata, options?: any): void;
}

interface Errors {
  [key: string]: string;
}

interface ValidationResult {
  errors: Errors | null;
  validateForm: () => Errors | null;
}

const useFormValidator = (
  formData: Formdata,
  validationSchema: ValidationSchema
): ValidationResult => {
  const [errors, setErrors] = useState<Errors>({});

  const validateForm = (): Errors | null => {
    try {
      validationSchema.validateSync(formData, { abortEarly: false });
      setErrors({});
      return null; // Form data is valid
    } catch (validationErrors) {
      const errorMessages: Errors = {};
      (validationErrors as Yup.ValidationError).inner.forEach((error: any) => {
        errorMessages[error.path] = error.message;
      });
      setErrors(errorMessages);
      return errors; // Return the validation errors
    }
  };

  return { errors, validateForm };
};

export default useFormValidator;
