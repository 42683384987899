export const actionTypes = {
  // Membership
  GETUSER_SUCCESS: "getUserSuccess",
  GETUSER_LOADING: "getUserLoading",
  GETUSER_ERROR: "getUserError",

  GETUSERBYID_SUCCESS: "getUserByIdSuccess",
  GETUSERBYID_LOADING: "getUserByIdLoading",
  GETUSERBYID_ERROR: "getUserByIdError",

  ADDUSER_SUCCESS: "addUserSuccess",
  ADDUSER_LOADING: "addUserLoading",
  ADDUSER_ERROR: "addUserError",

  UPDATEUSER_SUCCESS: "updateUserSuccess",
  UPDATEUSER_LOADING: "updateUserLoading",
  UPDATEUSER_ERROR: "updateUserError",

  DELETEUSER_SUCCESS: "deleteUserSuccess",
  DELETEUSER_LOADING: "deleteUserLoading",
  DELETEUSER_ERROR: "deleteUserError",

  GETCHAMBERBYID_SUCCESS: "getChamberByIdSuccess",
  GETCHAMBERBYID_LOADING: "getChamberByIdLoading",
  GETCHAMBERBYID_ERROR: "getChamberByIdError",

  GETservice_id_SUCCESS: "getservice_idSuccess",

  // Hall management

  GETHALL_SUCCESS: "getHallSuccess",
  GETHALL_LOADING: "getHallLoading",
  GETHALL_ERROR: "getHallError",

  GETHALLBYID_SUCCESS: "getHallByIdSuccess",
  GETHALLBYID_LOADING: "getHallByIdLoading",
  GETHALLBYID_ERROR: "getHallByIdError",

  ADDHALL_SUCCESS: "addHallSuccess",
  ADDHALL_LOADING: "addHallLoading",
  ADDHALL_ERROR: "addHallError",

  UPDATEHALL_SUCCESS: "updateHallSuccess",
  UPDATEHALL_LOADING: "updateHallLoading",
  UPDATEHALL_ERROR: "updateHallError",

  DELETEHALL_SUCCESS: "deleteHallSuccess",
  DELETEHALL_LOADING: "deleteHallLoading",
  DELETEHALL_ERROR: "deleteHallError",

  // Equipment Management

  GETEQUIPSBYID_SUCCESS: "getequipsByIdSuccess",
  GETEQUIPSBYID_LOADING: "getequipsByIdLoading",
  GETEQUIPSBYID_ERROR: "getequipsByIdError",

  GETEQUIPSBYHALLID_SUCCESS: "getequipsByHallIdSuccess",
  GETEQUIPSBYHALLID_LOADING: "getequipsByHallIdLoading",
  GETEQUIPSBYHALLID_ERROR: "getequipsByHallIdError",

  ADDEQUIP_SUCCESS: "addequipSuccess",
  ADDEQUIP_LOADING: "addequipLoading",
  ADDEQUIP_ERROR: "addequipError",

  UPDATEEQUIP_SUCCESS: "updateEquipSuccess",
  UPDATEEQUIP_LOADING: "updateEquipLoading",
  UPDATEEQUIP_ERROR: "updateEquipError",

  DELETEEQUIP_SUCCESS: "deleteEquipSuccess",
  DELETEEQUIP_LOADING: "deleteEquipLoading",
  DELETEEQUIP_ERROR: "deleteEquipError",

  // Event management

  GETEVENTBYCHAMBERID_SUCCESS: "getEventByChamberIdSuccess",
  GETEVENTBYCHAMBERID_LOADING: "getEventByChamberIdLoading",
  GETEVENTBYCHAMBERID_ERROR: "getEventByChamberIdError",

  GETEVENTBYID_SUCCESS: "getEventByIdSuccess",
  GETEVENTBYID_LOADING: "getEventByIdLoading",
  GETEVENTBYID_ERROR: "getEventByIdError",

  ADDEVENT_SUCCESS: "addEventSuccess",
  ADDEVENT_LOADING: "addEventLoading",
  ADDEVENT_ERROR: "addEventError",

  UPDATEEVENT_SUCCESS: "updateEventSuccess",
  UPDATEEVENT_LOADING: "updateEventLoading",
  UPDATEEVENT_ERROR: "updateEventError",

  DELETEEVENT_SUCCESS: "deleteEventSuccess",
  DELETEEVENT_LOADING: "deleteEventLoading",
  DELETEEVENT_ERROR: "deleteEventError",
};
